import styled from "styled-components";

export const StyledTooltip = styled.div<{ top: number; left: number }>`
  background: #3a3a48;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  color: #fff;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  padding: 7px 10px;
  pointer-events: none;
  position: absolute;
  text-align: center;
  transform: translate(-50%, -50%);
  z-index: 100;

  ${props => `
    top: ${props.top}px;
    left: ${props.left}px;
  `}
`;

export const USAMapTooltip = styled.div<{ top: number; left: number }>`
  background: #3a3a48;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  color: #fff;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  padding: 7px 10px;
  pointer-events: none;
  position: absolute;
  text-align: center;
  transform: translate(-50%, -50%);
  z-index: 100;

  ${props => `
    top: ${props.top}px;
    left: ${props.left}px;
  `}
`;

export const DisabledSinglePurchaseToggleTooltip = styled.div<{ top: number; left: number }>`
  background: #3a3a48;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 12px;
  min-width: 200px;
  padding: 7px 10px;
  pointer-events: none;
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 100;

  ${props => `
    top: ${props.top}px;
    left: ${props.left}px;
  `}
`;

export const StyledTooltipContainer = styled.div`
  background: #3a3a48;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  color: #fff;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  opacity: 0.9;
  padding: 7px 10px;
`;

export const StyledSpan = styled.span`
  margin: 2px;
`;

export const StyledInnerSpan = styled.span`
  text-decoration: underline;
`;

export const NoData = styled.div`
  color: red;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

export const TabsContainer = styled.div`
  > div {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  #subscription-programs {
    width: 80%;
  }

  .Polaris-FormLayout__Title {
    font-size: 1.6rem;
    font-weight: 600;
  }
`;

export const StyledTooltipHeading = styled.div`
  display: flex;
  font-weight: bold;
  justify-content: center;
  text-decoration: underline;
  white-space: nowrap;
`;

export const StyledCenteredText = styled.div`
  display: flex;
  justify-content: center;
  white-space: nowrap;
`;

export const StyledTooltipSpanLeft = styled.span`
  margin-right: 20px;
`;

export const StyledTooltipSpanRight = styled.span`
  margin-left: 20px;
`;

export const SpaceBetween = styled.div`
  min-width: 10px;
`;

export const StyledTooltipRow = styled.div`
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
`;

export const StyledTooltipRowUnderlined = styled.div`
  display: flex;
  justify-content: space-between;
  text-decoration: underline;
  white-space: nowrap;
`;

export const StyledTooltipSpanLeftUnderlined = styled.span`
  margin-right: 4px;
  text-decoration: underline;
`;

export const StyledTooltipDivLeft = styled.div`
  margin-right: 20px;
`;

export const StyledTooltipDivRight = styled.div`
  margin-left: 20px;
`;

export const WebhookContainer = styled.div`
  & .Polaris-LegacyCard {
    padding: 2rem;
  }

  & .Polaris-Labelled__Action {
    max-height: 20px;

    & .Polaris-Button__Text {
      color: #d82c0d;
    }
  }

  & .Polaris-Button--plain:hover {
    text-decoration: none;
  }
`;

export const LearnMoreIconWrapper = styled.div`
  .Polaris-Icon__Svg {
    fill: #2c6ecb;
  }
`;

export const LearnMoreLinkContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const LearnMoreAboutLink = styled.a`
  display: flex;
  flex-direction: row;
`;
